:root {
  --primery-white: #fff;
  --brand-color-2: #56dcf9;
  --content-color: #212121;

}


body {
  overflow-x: hidden;
}



/*  LOCOMOTIVE SCROLL STYLES STARTS */
html.lenis {
  height: auto
}

.lenis.lenis-smooth {
  scroll-behavior: auto
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain
}

.lenis.lenis-stopped {
  overflow: hidden
}

.lenis.lenis-scrolling iframe {
  pointer-events: none
}

/*  LOCOMOTIVE SCROLL STYLES ENDS */




/* this is the modal calss to remove the default border styling */
.bottom-remove {
  border: none !important;
}


/* Navigation menubar stylings starts */

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 0 !important;
  align-items: center;

}

/* remove this class based on mediaquries stylings.. */
.brnad-logo {
  /* width: 168px;
  height: 52px;
  object-fit: contain; */
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.brand-logo-wraper {
  position: absolute;
  top: 4%;
  width: 144.41px;
  height: 28.08px;


}

.hide_cc {
  display: none;
}

.links {
  list-style: none;
  display: flex;

}

.link {
  margin-right: 23px;
}

.link a {
  text-decoration: none;
  color: var(--content-color);
  font-family: Raleway;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;

}

.isLinkActive a {
  color: #1565C0;
}

.scrolled {
  background-color: #ffffff;
  /* Change this to your desired background color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Add box-shadow when scrolled */
}


/* Navigation menubar stylings ends */


/* [2] */


/* Landingpage stylings starts */
header {
  min-height: 100vh;

}

.banner-ptext {
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.94px;
  text-align: left;

}

.carousel-control-next,
.carousel-control-prev {
  position: absolute;
  top: 0;
  display: none !important;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: 0 0;
  border: 0;
  opacity: .5;
  transition: opacity .15s ease;
}

/* .banner-img-wraper:hover{

} */



/* Landingpage stylings Ends */








/* [3] */








/* BANNER SECTION STYLINGS STARTS.. */

.banner-wraper-card {
  transition: 0.5s;
}

.banner-wraper-card:hover {
  transform: translateY(-20px);
}



.banner-card h2 {
  color: var(--brand-color-2);
  width: max-content;
}

.banner-card p {
  color: var(--primery-white);
}




/* BANNER SECTION STYLINGS ENDS.. */








/* [4] */





/* SERVICE SECTION STYLINGS STARTS */



/* .main-headlines:hover{
  color: var(--primery-white);
  -webkit-text-stroke: 2px var(--content-color);
}
 */



.cotent-typography {
  font-family: Raleway;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.94px;
  text-align: center;

}



/* SERVICE SECTION STYLINGS ENDS */








/* [5]*/
/* CAUSTUDY-MAIN */
/* RIGHT CARD STYLINGS STARTS */
/* .caus-container.container {
  min-height: 100vh;
} */

.content-tile {
  font-family: Raleway;
  color: #1565C0;
}

.content-subTitle {
  font-family: Raleway;
  font-size: 20px;
  font-weight: 600;
  line-height: 23.48px;
  text-align: left;

}

.img-wraper img,
#left-img {
  transition: all 0.2s ease-in-out;
}



.img-inside-wraper {
  overflow: hidden;
}

.img-inside-wraper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.2s ease-in-out;
}

.img-inside-wraper img:hover {
  transform: scale(1.1);
}



.img-wraper-leftOne {
  overflow: hidden;
}

.img-wraper-leftOne img {
  transition: all 0.2s ease-in-out;
}

.img-wraper-leftOne img:hover {
  transform: scale(1.1);
}

#left-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* RIGHT CARD STYLINGS ENDS */



/* [6] */



/* TYPOGRAPHY SECTON STARTS */


.typography-span {
  color: #1565C0;

}



/* TYPOGRAPHY SECTON ENDS */



/* [7] */

/* CONTACT STYLINGS STARTS */


error {
  color: #f03839;
  font-size: 13px;
}



.span-label {
  font-family: Raleway;
  font-size: 18px;
  font-weight: 600;
  line-height: 21.94px;
  /* text-align: left; */
}

.send-btn {
  background-color: #1565C0;
  color: var(--primery-white);
}

/* CONTACT STYLINGS ENDS */







/* [8] */



/* FOOTER SECTION STARTS */
footer {
  background-color: var(--primery-white);
}

.footer-heades {
  font-family: Raleway;
  font-size: 35px;
  font-weight: 700;
  line-height: 41.09px;
  text-align: left;
  color: var(--content-color);
  width: max-content;
  /* margin-bottom: 2rem; */
}


.bottom-footer-card a {
  text-decoration: none;
  color: var(--content-color);
}

.bottom-footer-card a:hover {
  text-decoration: underline;
  color: var(--content-color);
}



.footer-contens {
  font-family: Raleway;
  font-size: 20px;
  font-weight: 400;
  line-height: 24.38px;
  text-align: left;
  color: var(--content-color);

}

.bottom-footer-contens {
  width: max-content;

}

.top-footer-card a {
  text-decoration: none;
  color: var(--content-color);
}

.socila-url .footer-contens {

  text-decoration: underline;
}


.link_line>.bottom-footer-contens:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #0095FE;
}

/* FOOTER SECTION ENDS */


/* PROJECT ESTIMATION PAGE STARTS */
.inside_round_box {
  background: #BFA9FF;
}

.other_checkbox {
  height: 50px;
}

/* PROJECT ESTIMATION PAGE ENDS */



















/*  MEDIAQURIES */

/* lg */
@media screen and (min-width:992px) {

  .mobile_logo {
    display: none;
  }


  .mobile-estimate-btn button {
    display: none;
  }

  .main-headlines {
    margin-top: 2rem;
  }


  .layout {
    min-height: 100vh !important;
  }

  .svg-wraper {
    top: 5rem;
    left: 50%;
  }

  /* REMOVING CONTACT BUTTON */
  .modal-btn-wraper .modal-btn {
    display: none;
  }

  .contact-btn-modal {
    display: none;
  }


  /* NAVBAR STARTS */
  .contact-btn {
    border: none;
    outline: none;
    background: #1565c0;
    color: var(--primery-white);
    border-radius: 30px;
    padding: 10px;
    width: 180px;
    height: 56px;
  }

  .link {
    margin-right: 23px;
    display: flex;
    flex-direction: column;
    height: 25px;
    overflow: hidden;
    transition: all 0.5s ease-in-out;
  }



  .navmenubar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--primery-white);
    transition: background-color 0.3s ease;
    z-index: 9999;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  }


  .nav-brand-logo {
    width: 168px;
    height: 52px;
    margin-right: 10rem !important;

  }

  .nav-brand-logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .link a {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: left;
    transition: all 0.3s ease-in-out;
  }

  .link:hover .test {
    transform: translateY(-20px);
  }

  .link_2 {
    color: #1565c0 !important;
  }





  /* NAVBAR ENDS */


  /* landing page starts */
  header {
    position: absolute;
    right: 0;
    left: 0;
    top: 4em;

  }

  .home-wrapper-carousel {
    width: 100%;
  }

  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    /* min-height: 100vh; */
  }

  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 20px !important;
    height: 20px !important;
    border-radius: 50% !important;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #1565C0 !important;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
  }

  .carousel-indicators [data-bs-target]:hover {
    animation: jello-horizontal 0.9s both;
  }

  @keyframes jello-horizontal {
    0% {
      transform: scale3d(1, 1, 1);
    }

    30% {
      transform: scale3d(1.25, 0.75, 1);
    }

    40% {
      transform: scale3d(0.75, 1.25, 1);
    }

    50% {
      transform: scale3d(1.15, 0.85, 1);
    }

    65% {
      transform: scale3d(0.95, 1.05, 1);
    }

    75% {
      transform: scale3d(1.05, 0.95, 1);
    }

    100% {
      transform: scale3d(1, 1, 1);
    }
  }



  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -4rem !important;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
  }

  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: -16.75rem !important;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
  }

  .home-wrapper-section {
    height: 100vh;
    width: 100%;
  }

  .home-carousel-h1 {
    font-family: Montserrat;
    font-size: 74px !important;
    font-weight: 700;
    line-height: 97.52px !important;
    text-align: center;
  }

  .home-carousel-h1-span {
    font-family: Montserrat;
    font-size: 74px !important;
    font-weight: 700;
    line-height: 97.52px !important;
    text-align: center;
    color: #007bff;
  }

  .home-carousel-p {
    font-family: Montserrat;
    font-size: 30px !important;
    font-weight: 600;
    line-height: 36.57px !important;
    text-align: center;

  }

  .home-carousel-span {
    font-family: Montserrat;
    font-size: 30px !important;
    font-weight: 400;
    line-height: 36.57px !important;
    text-align: center;
    color: #000000;
  }


  .button2 {
    width: 424.95px;
    height: 70.8px;
    border-radius: 10px !important;
    display: inline-block;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: #1565C0;
    padding: 0.7em 1.7em;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    border-radius: 0.5em;
    background: #FFFFFF;
    border: 1px solid #1565C0;
    margin-top: 3rem !important;
  }

  .button2:active {
    color: #666;
    box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
  }

  .button2:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }

  .button2:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color: #007bff;
    /* background-color: #009087; */
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }

  .button2:hover {
    color: #ffffff;
    border: 1px solid #009087;
  }

  .button2:hover:before {
    top: -35%;
    background-color: #007bff;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }

  .button2:hover:after {
    top: -45%;
    background-color: #1565C0;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }




  .inside-carousel {
    padding: 0 5rem;
  }

  /* landing page ends */

  /* BANNER 2 STYLINGS STARTS.. */
  .banner-section {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

  }



  .banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    /* width: 1475.81px; */
    /* height: 216.46px; */
    /* background: linear-gradient(87.62deg, #2998FE 0.72%, #4FA1FF 97.21%); */
  }

  .custom-row {
    background: linear-gradient(87.62deg, #2998FE 0.72%, #4FA1FF 97.21%);
    width: 100%;
    border-radius: 20px;
    height: 218px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .custom-row::before {
    content: " ";
    position: absolute;
    width: 100% !important;
    height: 216.46px !important;
    top: 1rem !important;
    background: #1565C0;
    z-index: -1;
    border-radius: 20px;
    left: 0.7rem !important;
  }



  .banner-line:nth-child(2) {
    border-left: 1px solid #FFFFFF80 !important;
    border-right: 1px solid #FFFFFF80 !important;
    border-top: none !important;
    border-bottom: none !important;
  }

  .banner-container h3 {
    font-family: Raleway;
    font-size: 35px !important;
    font-weight: 700;
    line-height: 41.09px !important;
    color: #FFFFFF;
    margin-bottom: 25.20px !important;
    width: max-content;
  }

  .banner-container span {
    font-family: Raleway;
    font-size: 18px !important;
    font-weight: 400;
    line-height: 21.94px !important;
    color: #FFFFFF;

  }

  .banner-card {
    width: 350px;
    height: 200px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    background: #00000080;
    border-radius: 35px;
    border: 1px solid #0095FE;
    padding: 20px 10px 20px 20px;
  }

  /* BANNER STYLINGS ENDS.. */


  /* SERVICE SECTION SYLINGS STARTS     */
  .main-headlines {
    font-family: Raleway;
    font-size: 80px !important;
    font-weight: 700 !important;
    line-height: 93.92px !important;
    color: var(--content-color);
    transition: all 0.2s ease-out;
  }

  section.service {
    /* background-color: #f5f5f2; */
    border-bottom: 1px solid #D7D7D7;
    /* margin-top: 7rem; */
  }

  .content-span-wraper {
    padding: 0 50px;
    /* padding: 0 90px; */
  }

  /* SERVICE SECTION SYLINGS ENDS     */

  /* CAROUSEL CARD STARTS */
  .cards-title {
    font-family: Raleway;
    font-size: 30px;
    font-weight: 700;
    line-height: 35.22px;
    /* width: max-content; */
    color: #00113C;

  }

  .cards-content {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    height: 150px;

    text-overflow: ellipsis;
    overflow: hidden;
  }




  .box-right,
  .box-left {
    position: relative;
    padding: 0 10px;
    /* border: 1px solid;
    border-radius: 15px; */
  }


















  /* .cards:hover {
    animation: tadaLeft .5s forwards;

  } */



  /* HIDING THE CAROUSEL SECTION  */
  .carousel-wraper,
  .slider-container {
    display: none !important;
  }

  /* .slider-container{
    display: none !important;
  } */


  /* CAROUSEL CARD ENDS */




  /* LOGOSCROLL STARTS */
  .logos-wraper {
    width: 190px;
    height: 60px;
    margin-right: 4rem;

  }

  .marquee-wraper {
    position: relative;
    overflow-x: hidden;
  }

  .marquee-wraper::before {
    position: absolute;
    top: 0;
    left: -4%;
    content: "";
    /* background-color: yellow; */
    width: 70px;
    height: 100%;
    border-radius: 50%;
    z-index: 999;
  }

  .marquee-wraper::after {
    position: absolute;
    top: 0;
    right: -4%;
    content: "";
    /* background-color: yellow; */
    width: 70px;
    height: 100%;
    border-radius: 50%;
    z-index: 999;
  }



  .logos-wraper img {
    width: 100%;
    height: 100%;
    object-fit: contain;

  }

  /* LOGOSCROLL ENDS */









  /* not found starts  */
  section.notFOund-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  /* not found ends */




  .img-wraper {
    width: 100% !important;
    height: 100% !important;
    /* height: 500px; */

  }

  .img-wraper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .img-wraper-leftOne {
    /* width: 392.05px;
    height: 189.13px; */
    width: 100%;
    height: 375px;
    /* height: 100%; */
  }

  .img-inside-wraper {
    width: 175px;
    height: 170px;
  }



  .content-tile {
    font-family: Raleway;
    font-size: 46px;
    /* font-size: 50px; */
    font-weight: 700;
    line-height: 58.7px;
  }

  .contents {
    font-family: Raleway;
    font-size: 17px;
    font-weight: 400;
    line-height: 24.38px;
    text-align: left;
  }

  .custom-dotted-div {
    border-bottom: 5px dotted #AFAFAF;
    width: 400px;
    height: 69px;
  }

  /* RIGHTCARD STYLINGS ENDS */

  /* TYPOGRAPHY SECTION STARTS */
  .typography-h1 {
    font-family: Raleway;
    font-size: 80px;
    font-weight: 700;
    line-height: 84.7px;
    text-align: center;

  }

  .typography-section {
    min-height: 100vh;
    /* background-color: salmon;
    position: sticky;
    top: 10px; */
  }

  /* TYPOGRAPHY SECTION ENDS */


  /* CONTACT STYLINGS STARTS */

  .margin-container {
    margin-top: 6rem;
    margin-bottom: 6rem;

  }

  .contact-wraper {
    background-color: #F2F2F2;
  }

  .contact-h1 {
    font-family: Raleway;
    font-size: 76px;
    /* font-size: 80px; */
    font-weight: 700;
    line-height: 93.92px;
    text-align: left;
    color: #212121;

  }

  .span-label {
    margin-left: 0.4rem;
  }

  .contact-h2 {
    font-family: Raleway;
    font-size: 50px;
    font-weight: 400;
    line-height: 58.7px;
    text-align: left;
    color: #1565C0;
  }


  .form-inputs {
    width: 80%;
    height: 54.93px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: left;


  }

  textarea {
    resize: vertical;
    width: 80% !important;
    height: 147px;
    border: 1px solid #BFBFBF;
    border-radius: 6px;
    font-family: Raleway !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 21.94px;
    color: #817A7A;


    /* text-align: left; */

  }

  error {
    width: 80%;
    /* margin-bottom: 15px; */
  }

  label.optional-label {
    width: 80%;
    margin: 5px 0;
  }

  .span-wraper {
    width: 80%;
  }

  .send-btn {
    width: 80%;
    min-height: 54px;
    border: none;
    outline: none;
    border-radius: 6px;
    font-family: Raleway;
    font-size: 20px;
    font-weight: 700;
    line-height: 23.48px;
    /* text-align: left; */

  }

  .feedback-card {
    width: 557.46px;
    height: 309.88px;
    top: 14231.6px;

    border-radius: 28px;
    border: 1px solid #BFBFBF;
  }

  .feedback-card h1 {
    font-family: Montserrat !important;
    font-size: 30px !important;
    font-weight: 600 !important;
    line-height: 36.57px !important;
    text-align: left;
    color: #000000 !important;

  }

  .feedback-team {
    font-family: Montserrat;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 24.38px;


  }

  .feedback-desc {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.38px;


  }

  /* CONTACT STYLINGS ENDS */

  /* FOOTER SECTION STYLINGS STARTS */
  .top-footer-card {
    width: max-content;
    /* background-color: yellow; */
  }


  .footerLogo {
    width: 341.81px;
    height: 81.47px;
  }

  .footerLogo img {
    width: 100%;
    height: 100%;
  }


  span.bottom-footer-span {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
  }

  .bottom-footer-contens {
    width: max-content;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 400;
    line-height: 16.13px;
    color: #00113C;
  }



  .optional-margin {
    margin-top: 5.5rem;
    margin-bottom: 3rem;
  }

  .footer-sub-heads {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.13px;
    text-align: left;
    color: #00113C;
    width: max-content;
  }

  /* FOOTER SECTION STYLINGS ENDS */

  /* CAROUSEL STARTS */
  .icons {
    width: 60px;
    height: 60px;
  }

  .icons img {
    width: 100%;
    height: 100%;
    object-fit: contain;

  }

  /* CAROUSEL CUSTIOM STYLING STARTS */
  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 120% !important;
    bottom: 10px !important;
    display: block;
    width: 60px !important;
    height: 60px !important;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    background: #a9c6e8 !important;
    border-radius: 50% !important;
  }

  .slick-next {
    right: 40% !important;

  }

  .slick-prev {
    left: 45% !important;
    /* left: 40% !important; */
    z-index: 99 !important;
  }

  /* CAROUSEL CUSTIOM STYLING ENDS */
  /* CAROUSEL ENDS */

  /*LG PROJECT ESTIMATION PAGE STARTS */
  .inside_round_box {
    border-radius: 50%;
    background: #BFA9FF;
    width: 400px;
    height: 400px;
    left: 23rem;
    top: 7rem;
  }

  .estimate_first_col_wraper h1 {
    font-family: Raleway;
    font-size: 80px;
    font-weight: 700;
    line-height: 93.92px;
    text-align: left;
    color: var(--content-color);
    width: max-content;
  }

  .estimate_first_col_wraper span {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    color: #000000;
  }

  .filed_inputs {
    width: 100%;
    height: 54.93px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    padding-left: 10px;
    color: #817A7A;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: left;
  }

  .golbal_label {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    text-align: left;
    color: #000000;
    /* width: max-content; */
  }

  .grid-item.col-lg-4 {
    width: max-content;
  }

  .p-estimate-textArea {
    resize: vertical;
    width: 100% !important;
    height: 258.21px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
  }

  .checkboxLabel {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    color: #000000;
  }

  select {
    height: 54.93px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    color: #817A7A;
    padding-left: 15px;
  }

  .img-1 {
    height: 500px;
    left: 27rem;
    top: 1rem;
  }

  .img-2 {
    width: 600px;
    top: 10rem;
    left: 4rem;
  }

  .img-3 {
    width: 250px;
    top: 20rem;
    left: 5rem;
  }







  .estimate_submit_btn {
    /* width: 557.46px; */
    width: 100%;
    height: 65.18px;
    border-radius: 6px;
    background: #1565C0;
    color: var(--primery-white);
    border: none;
    transition: all 0.2s ease-in;
  }

  .estimate_submit_btn:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
    background-color: #1E40AF;
  }

  .p-estimate-btn {
    background: #1565C0;
    color: var(--primery-white);
    border: none;
    outline: none;
    height: 56.9px;
    width: 201.35px;
    border-radius: 10px;
    transition: all 0.2s ease-in;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    color: var(--primery-white);

  }

  .p-estimate-btn:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
  }

  textarea#textBOx {
    width: 360.33px;
    height: 50.22px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    background: #FFFFFF;
  }

  #estimate_sm {
    display: none;
  }

  .mobile_logo {
    display: none;
  }

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    transition: all 0.2s ease-in-out;
    /* Border color */
    border-radius: 4px;
    /* Rounded corners */
    outline: none;
    /* Remove focus outline */
    cursor: pointer;
    /* Show pointer cursor on hover */
    margin-right: 5px;
    /* Adjust spacing between checkbox and label */
  }

  input[type="checkbox"]:checked {
    background-color: #007bff;
    /* border-radius: 50%; */
    /* Background color when checked */
    border-color: #007bff;
    /* Border color when checked */
  }

  .estimate_feedback-card {
    width: 100%;
    height: 300px;
    background-color: var(--primery-white);
    border-radius: 30px;
    border: 1px solid #000;
  }

  /* PROJECT ESTIMATION PAGE ENDS */


  /* ANIMATION AND VFX STARTS */
  .amvfx-section {
    margin-top: 10rem;
  }

  .am-vfx-row {
    margin-top: 6rem;
  }


  .amvfx-h1 {
    font-family: Raleway;
    font-size: 80px;
    font-weight: 700;
    line-height: 97.52px;
    width: max-content;
  }

  .amvfx-span {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: center;
    color: #000000;
    padding: 0 10rem;
  }

  .amvfx-img-wrapper {
    width: 794.04px;
    height: 453.74px;
    border-radius: 35px;
    overflow: hidden;
  }

  .amvfx-img-wrapper img {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
  }





  .am-vfx-h3 {
    font-family: Raleway;
    font-size: 50px;
    font-weight: 800;
    line-height: 60.95px;
    color: #1565C0;
    text-decoration: underline;
  }

  .am-vfx-add-h3 {
    font-family: Raleway;
    font-size: 50px;
    font-weight: 800;
    line-height: 60.95px;
    color: #1565C0;
    text-decoration: underline;
  }

  .am-vfx-h3 span {
    font-family: Raleway;
    font-size: 50px;
    font-weight: 800;
    line-height: 58.7px;

  }

  .amfImage {
    width: 451px;
  }

  .amfImage img {
    width: 100%;
  }

  .am-vfx-card {
    margin-bottom: 50px !important;
    position: relative;
  }





  .am-vfx-card h5 {
    font-family: Raleway;
    font-size: 30px;
    font-weight: 700;
    line-height: 35.22px;
    color: #00113C;


  }

  .am-vfx-card span {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    color: #000000;
  }

  .am-vfx-svg-wrapper {
    top: 0;
    left: 50%;
  }

  .am-vfx-addS-svg {
    top: 8rem;
    left: 50%;
  }

  .am-vfx-addSection {
    margin-top: 10rem;
  }

  /* ANIMATION AND VFX ENDS */


  /* NEW ABOUT SECTION LG STARTS */
  .abt-h1 {
    font-family: Raleway;
    font-size: 75px;
    font-weight: 700;
    line-height: 97.52px;
    color: #000000;

  }

  .defult-mb {
    margin-bottom: 5rem;
  }

  .defult-mt {
    margin-top: 5rem !important;
  }

  .about-phrgh {
    font-family: Raleway;
    font-size: 22px;
    font-weight: 400;
    line-height: 26.82px;
    text-align: center;
    color: #000000;

  }

  .abut-p {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: center;
    color: #000000;
    padding: 0 10rem;
  }

  .color_point {
    width: 35px;
    height: 35px;
    border-radius: 50%;
  }

  .abt-cont h5 {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 700;
    line-height: 26.82px;
    color: #000000;
  }

  .abt-cont p {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    color: #000000;
    margin-left: 3rem;

  }

  .abt-card-box {
    z-index: 999;
  }

  .abt-card {
    background: #F8F8F8;
    /* width: 517.32px;
    height: 180.5px; */
    margin-bottom: 84px;
  }




  .abt-card-h4 {
    font-family: Raleway;
    font-size: 22px;
    font-weight: 700;
    line-height: 26.82px;
    color: #000000;
  }

  .abt-card-span {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    color: #000000;


  }

  .about_svg_wraper {
    position: absolute;
    top: -1rem;
    left: -2rem;
    /* left: 0; */
    transform: translate(35px, 10px);
  }

  .round-div-1,
  .round-div-2,
  .round-div-3,
  .round-div-4,
  .round-div-5,
  .round-div-6,
  .round-div-7 {
    width: 30px;
    height: 30px;
    background: #fff;
    border: 4px solid #1565C0;
    border-radius: 50%;
    position: absolute;
    left: 48.6%;
  }

  .round-div-1 {
    top: 0;
  }

  .round-div-2 {
    top: 8rem;
  }

  .round-div-3 {
    top: 16rem;
  }

  .round-div-4 {
    top: 24rem;
  }

  .round-div-5 {
    top: 32rem;
  }

  .round-div-6 {
    bottom: 9rem;
  }

  .round-div-7 {
    bottom: 0;
  }

  /* NEW ABOUT SECTION LG ENDS */


  /* DESIGNPAGE SECTION STARTS LG */

  .designPage-section {
    overflow-x: hidden;
  }

  .designPage-svg-wraper {
    top: 0;
    left: 50%;
  }

  .designpage-container {
    margin-top: 6rem;
  }

  /* DESIGNPAGE SECTION ENDS LG */


  /* NOTFOUND PAGE STARTS */
  .notFound_img_wraper {
    width: 100%;
    height: 481.59px;
    border-radius: 36px;
    overflow: hidden;
  }

  .notFound_img_wraper img {
    width: 100%;
    height: 100%;
  }

  .notfound_title {
    font-family: Raleway;
    font-size: 73px;
    font-weight: 800;
    line-height: 85.7px;
    text-align: left;
    color: #000000;
  }

  .notfound-desc {
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 400;
    line-height: 29.26px;
    text-align: left;
    color: #000000;
  }

  /* NOTFOUND PAGE ENDS */
}






/* XXL */
@media screen and (min-width: 1400px) {


  header {
    position: absolute;
    right: 0;
    left: 0;
    top: 0 !important;
  }

  .home-wrapper-carousel {
    width: 100%;
  }

  .spacing_h1 {
    padding: 4rem 0;
  }

  .carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden;
    display: flex;
    height: 80vh;
    align-items: center;
  }

  .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: -16.75rem !important;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center;
  }

  .carousel-indicators {
    position: absolute;
    right: 0;
    /* bottom: -1.5rem !important; */
    bottom: -2.5rem !important;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
  }

  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 30px;
    padding: 0;
    margin-right: 16px !important;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
    cursor: pointer;
  }

  .home-wrapper-section {
    height: 100vh;
    width: 100%;
  }

  .home-carousel-h1 {
    font-family: Raleway;
    font-size: 74px !important;
    font-weight: 700 !important;
    line-height: 97.52px !important;
    text-align: center;
    height: 195px;
  }

  .home-carousel-h1-span {
    font-family: Raleway;
    font-size: 74px !important;
    font-weight: 700 !important;
    line-height: 97.52px !important;
    text-align: center;
    color: #007bff;
  }

  .home-carousel-p {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 600;
    line-height: 36.57px;
    text-align: center;

  }

  .home-carousel-span {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 400;
    line-height: 36.57px;
    text-align: center;
    color: #000000;
  }

  .home-crousel-button {
    width: 424.95px;
    height: 70.8px;
    border-radius: 10px;
    border: 1px solid #1565C0;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    color: #1565C0;
    background: #FFFFFF;
  }

  .inside-carousel {
    padding: 0 5rem;
  }

  /* landing page styles end */



  /* SECOND BANNER STYLINGS STARTS */
  .banner-section {
    /* height: 50vh !important; */
    height: 30vh !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* padding-top: 10rem; */
  }

  .custom-row::before {
    content: " ";
    position: absolute;
    width: 100%;
    height: 216.46px;
    background: #1565C0;
    z-index: -1;
    border-radius: 20px;
    top: 2rem !important;
    left: 1.8rem !important;
    /* top: -1rem !important;
    left: 2rem !important; */
  }

  .banner-card {
    width: 426.44px;
    height: 261.59px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    background: #00000080;
    border-radius: 35px;
    border: 1px solid #0095FE;
    padding: 20px;
  }




  /* SECOND BANNER STYLINGS ENDS */



  /* TYPOGRAPHY SECTION STARTS */
  .typography-h1 {
    font-family: Raleway;
    font-size: 50px;
    font-weight: 700;
    line-height: 58.7px;
    text-align: center;

  }

  .typography-section {
    min-height: 40vh;
    /* min-height: 50vh; */
  }

  /* TYPOGRAPHY SECTION ENDS */




  /* CAUSTUDT-XXL */
  /* RIGHT CARD STARTS */
  .contents {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.38px;
    text-align: left;
  }

  .img-inside-wraper {
    /* width: 249px; */
    width: 249px;
    height: 249px;
  }

  .img-wraper-2 {
    gap: 10px;
  }

  /* RIGHT CARD ENDS */



  /* CONTACT STYLINGS STARTS */

  .margin-container {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .contact-h1 {
    font-family: Raleway;
    font-size: 80px;
    font-weight: 700;
    line-height: 93.92px;
    text-align: left;
    color: #212121;

  }

  .contact-h2 {
    font-family: Raleway;
    font-size: 50px;
    font-weight: 400;
    line-height: 58.7px;
    text-align: left;
    color: #1565C0;

  }

  .span-label {
    margin-left: 0rem;
  }

  .span-wraper {
    width: 557.46px !important;
  }

  .form-inputs {
    width: 557.46px !important;
    height: 54.93px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: left;
  }

  textarea {
    resize: vertical;
    width: 557.46px !important;
    height: 147px;
    border: 1px solid #BFBFBF;
    border-radius: 6px;
    font-family: Montserrat !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 21.94px;
    color: #817A7A;
    /* text-align: left; */
  }

  .button {
    width: 557.46px !important;
  }

  /* CONTACT STYLINGS ENDS */


  /* SERVICE SECTION STARTS */

  .box-left::after {
    content: '';
    position: absolute;
    bottom: 12px !important;
    right: -7%;
    width: 20px;
    height: 20px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 46px solid #f5f5f5;
    /* border-bottom: 46px solid #0094fe3b; */
    transform: rotate(91deg);
    opacity: 0;
  }

  .box-right::after {
    content: '';
    position: absolute;
    top: 0;
    left: -7%;
    width: 20px;
    height: 20px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 46px solid #f5f5f5;
    /* border-bottom: 46px solid #0094fe3b; */
    transform: rotate(-88deg);
    opacity: 0;
  }

  .cards {
    /* width: 405px;
    height: 400px; */
    background-color: var(--primery-white);
    border-radius: 20px;
    position: relative;
  }


  .carousel-wraper {
    display: none;
  }

  .svg-wraper {
    top: 2rem !important;
    left: 50%;
  }

  /* SERVICE SECTION ENDS */
  #footer-contens {
    width: max-content;
  }

  .about-row {
    margin-top: 3rem !important;
  }

  /*LG PROJECT ESTIMATION PAGE STARTS */
  .estimate_section_wraper {
    overflow-x: hidden;
  }

  .inside_round_box {
    background: #BFA9FF;
    width: 626.59px;
    height: 626.59px;
    border-radius: 50%;
    top: 3rem;
    left: 23rem;
    /* left: 15%; */

  }

  .filed_inputs {
    width: 557.46px;
    height: 54.93px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    padding-left: 10px;
  }


  .otherTextBox {
    width: 420.33px !important;
    height: 50.22px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    background: #FFFFFF;
    margin-left: 20px;
  }

  .p-estimate-btn {
    background: #1565C0;
    color: var(--primery-white);
    border: none;
    outline: none;
    height: 56.9px;
    width: 201.35px;
    border-radius: 10px;
    transition: all 0.2s ease-in;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
  }

  .p-estimate-textArea {
    resize: vertical;
    width: 557.46px !important;
    height: 258.21px;
    border-radius: 6px;
    color: #817A7A;
    border: 1px solid #BFBFBF;
  }

  .estimate_submit_btn {
    width: 557.46px !important;
    height: 65.18px;
    border-radius: 6px;
    background: #1565C0;
    color: var(--primery-white);
    border: none;
    transition: all 0.2s ease-in;
  }

  select {
    height: 54.93px;
    width: 557.46px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    color: #817A7A;
  }


  .img-1 {
    top: -7rem;
    left: 26rem;
    width: 377.47px;
    height: 778.82px;

  }

  .img-2 {
    /* top: 6rem;
    left: -4rem;
    width: 977.82px;
    height: 651.96px; */


    /* top: 8rem;
    left: -9rem;
    width: 977.82px;
    height: 651.96px; */

    top: 9rem;
    left: -4rem;
    width: 877.82px;
    height: 551.96px;


  }

  .img-3 {
    /* top: 21rem !important;
    width: 447.41px !important;
    left: -7px !important;
    object-fit: contain; */

    /* 2 */

    /* top: 27rem !important;
    width: 447.41px !important;
    left: -25px !important;
    object-fit: contain; */

    top: 25rem !important;
    width: 347.41px !important;
    left: 2rem;
    object-fit: contain;


  }

  /* PROJECT ESTIMATION PAGE ENDS */

  /* ANIMATION AND VFX STARTS */
  .amvfx-section {
    margin-top: 12rem;
  }

  .am-vfx-row {
    margin-top: 6rem;
  }


  .amvfx-h1 {
    font-family: Montserrat;
    font-size: 80px;
    font-weight: 700;
    line-height: 97.52px;
    width: max-content;
  }

  .amvfx-span {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: center;
    color: #000000;
    padding: 0 10rem;
  }

  .amvfx-img-wrapper {
    width: 794.04px;
    height: 453.74px;
    border-radius: 35px;
  }

  .amfImage {
    width: 451px;
    position: absolute;
    left: 4rem;
  }

  .amvfx-img-wrapper img {
    width: 100%;
    height: 100%;

  }

  .am-vfx-h3 {
    font-family: Montserrat;
    font-size: 50px;
    font-weight: 800;
    line-height: 60.95px;
    color: #1565C0;
    /* width: 627.87px; */
  }

  .am-vfx-h3 span {
    font-family: Raleway;
    font-size: 50px;
    font-weight: 800;
    line-height: 58.7px;

  }

  .am-vfx-card {
    /* width: 627.87px; */
    height: 123.31px;
  }

  .am-vfx-card h5 {
    font-family: Raleway;
    font-size: 30px;
    font-weight: 700;
    line-height: 35.22px;
    color: #00113C;


  }

  .am-vfx-card span {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    color: #000000;
  }

  .am-vfx-svg-wrapper {
    top: 4rem;
    left: 50%;
    /* transform: translate(50px, 50px); */
  }

  .am-vfx-addS-svg {
    top: 7rem;
    left: 50%;
    /* transform: translate(50px, -50px); */
  }

  .amfImage img {
    /* width: 611.16px;
    height: 349.23px; */
    border-radius: 26px;
  }

  /* ANIMATION AND VFX ENDS */

  /*  ABOUT US PAGE STARTS XXL */
  .abt-cont p {
    margin-left: 3.2rem;
  }

  .abt-h1 {
    font-size: 80px;
  }

  .about_svg_wraper {
    position: absolute;
    top: -1rem;
    left: 0;
    transform: translate(6px, 10px);
  }

  .round-div-1,
  .round-div-2,
  .round-div-3,
  .round-div-4,
  .round-div-5,
  .round-div-6,
  .round-div-7 {
    width: 30px;
    height: 30px;
    background: #fff;
    border: 4px solid #1565C0;
    border-radius: 50%;
    position: absolute;
    left: 48.8%;
  }

  .abt-cont h5 {
    font-family: Raleway;
    font-size: 22px !important;
    font-weight: 700;
    line-height: 26.82px;
    color: #000000;
  }


  /*  ABOUT US PAGE ENDS XXL */

  /* DESIGNPAGE SECTION STARTS LG */
  .designPage-svg-wraper {
    top: 1rem;
    left: 50%;
    /* transform: translate(50px, 10px); */
  }

  .designpage-container {
    margin-top: 8rem !important;
    margin-bottom: 5rem !important;
  }

  .design-p {
    padding: 0 16rem !important;
  }

  /* DESIGNPAGE SECTION ENDS LG */

  /* NOTFOUND PAGE STARTS */
  .notFound_img_wraper {
    /* width: 676.34px; */
    /* height: 481.59px; */
    border-radius: 36px;
    overflow: hidden;
  }

  .notFound_img_wraper img {
    width: 100%;
    height: 100%;
  }

  /* NOTFOUND PAGE ENDS */



  /* FOOTER */

  .footerLogo {
    width: 341.81px;
    height: 81.47px;
    margin: 3rem 0;
  }

  .bottom-footer-contens {
    width: max-content;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    color: #00113C;
  }

  .optional-margin {

    margin-top: 5.4rem !important;
  }

  .abt-card {
    background: #F8F8F8;
    margin-bottom: 84px;
    height: 180.5px;
  }
}












/* SM FOR MOBILE DEVICES */
@media screen and (max-width: 767px) {

  /* hiding mobile */

  .wraper-contact {
    display: none;
  }

  /* amfvfx */
  .am-vfx-row {
    display: none;
  }

  .hide-estimate_mobile {
    display: none;
  }

  #Contact {
    display: none;
  }

  .margin-container {
    padding: 0;
  }

  section#contact {
    padding: 0 2rem;
  }

  .estimate_a {
    display: none;
  }


  li.slick-active button {
    background-color: #1EA1EB !important;
  }

  li.slick-active button::after {
    background: #1EA1EB;
    border-radius: 50%;
  }

  /* COMMON */
  .custom-dotted-div {
    border-bottom: 5px dotted #AFAFAF;
    width: 230px;
    margin: 40px 0;
  }

  .svg-wraper {
    display: none;
  }

  /* removing contact section from mobile devices.. */
  .contact-sectin-wraper {
    display: none;
  }

  /* HIDING THE ROW SECTIN IN MOBILE VIEWS */
  .hideIn-mobile {
    display: none;
  }


  /* NAVBAR STARTS */
  .mobile-nav {
    position: fixed;
    bottom: 0;
    background-color: var(--primery-white);
    border-radius: 20px 20px 0 0;
    z-index: 99;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 2);
  }

  /* hiding this button in mobile  */
  .contact-btn {

    display: none;


    /* width: 180px;
    height: 56px; */
  }

  /* hiding logo from navbar */
  .nav-brand-logo {
    display: none;
  }

  /* NAVBAR ENDS */


  /* LANDINGPAGE STYLINGS STARTS */

  header {
    min-height: 95vh;
  }

  .carousel-inner {
    min-height: 70vh;
    display: flex;
    align-items: center;
    /* justify-content: center; */
  }

  /* .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
    height: 500px !important;
    
  }

  .carousel_item_container {
    height: 100%;
  } */

  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 15px !important;
    border-radius: 50% !important;
    height: 15px !important;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #1565C0 !important;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
  }

  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -2rem !important;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
  }

  .home-carousel-h1 {
    font-family: Montserrat;
    font-family: Raleway;
    font-size: 30px;
    font-weight: 700;
    line-height: 35.22px;
    text-align: center;
    /* height: 110px; */

  }

  /* .inside-carousel{
    height: 220px;
  } */



  .home-carousel-h1-span {
    font-family: Raleway;
    font-size: 30px;
    font-weight: 700;
    line-height: 35.22px;
    text-align: center;
    color: #007bff !important;
  }

  .home-carousel-p {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 600;
    line-height: 21.13px;
    text-align: center;


  }

  .home-carousel-span {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    text-align: center;
    color: #000000;
  }

  .evr-landing-logo {
    top: 1.7rem;
    width: 144.41px;
    height: 28.08px;
  }

  .evr-landing-logo img {
    width: 100%;
    height: 100%;
  }

  .button2 {
    width: 354.81px;
    height: 70.8px;
    border-radius: 10px;
    display: inline-block;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: #1565C0;
    padding: 0.7em 1.7em;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    border-radius: 0.5em;
    background: #FFFFFF;
    border: 1px solid #1565C0;
  }

  .button2:active {
    color: #666;
    box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
  }

  .button2:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }

  .button2:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color: #007bff;
    /* background-color: #009087; */
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
  }

  .button2:hover {
    color: #ffffff;
    border: 1px solid #009087;
  }

  .button2:hover:before {
    top: -35%;
    background-color: #007bff;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }

  .button2:hover:after {
    top: -45%;
    background-color: #007bff;
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
  }


  .carousel-control-next,
  .carousel-control-prev {

    display: none !important;

  }






  /* LANDINGPAGE STYLINGS ENDS */



  /* BANNER CARDS STYLINGS STARTS */
  .custom-row {
    background: linear-gradient(87.62deg, #2998FE 0.72%, #4FA1FF 97.21%);
    width: 100%;
    /* width: 399.06px; */
    /* height: 611.4px; */
    border-radius: 20px;
  }

  .banner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .custom-row::before {
    content: "";
    position: absolute;
    background: #1565C0;
    top: 1rem;
    left: 0.5rem;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: -1;
  }

  .mobile-estimate-btn button {
    width: 354.81px;
    height: 70.8px;
    border-radius: 10px;
    background: #1565C0;
    border: 1px solid #1565C0;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    color: #FFFFFF;
    margin-top: 87px;

  }

  .banner-line:nth-child(2) {
    /* height: 128.41px; */
    border-bottom: 1px solid #FFFFFF80;
    border-top: 1px solid #FFFFFF80;
  }

  .banner-line h3 {
    font-family: Raleway;
    font-size: 25px !important;
    font-weight: 700 !important;
    line-height: 29.35px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 17px;
  }

  .banner-line span {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: left;
    color: #FFFFFF;

  }

  /* BANNER CARDS STYLINGS ENDS */

  /* SERVICE COMPONENT STARTS */
  .abt-h1,
  .amvfx-h1 {
    font-family: Raleway;
    font-size: 35px !important;
    font-weight: 700 !important;
    /* line-height: 97.52px; */
    color: #000000;
  }

  .abut-p,
  .amvfx-span {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: center;
    color: #000000;
    padding: 0 2rem;
  }

  .amvfx-img-wrapper {
    width: 100%;
    height: 300px;
    border-radius: 35px;
    overflow: hidden;
  }

  .amvfx-img-wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
  }

  .am-vfx-addS-svg {
    display: none;
  }

  .designPage-svg-wraper {
    display: none;

  }

  .amvfx-section {
    margin-top: 3rem !important;
  }

  .am-vfx-h3 {
    font-family: Raleway;
    font-size: 25px;
    font-weight: 800;
    line-height: 60.95px;
    color: #1565C0;
    text-decoration: underline;
  }

  .am-vfx-h3 span {
    font-family: Raleway;
    font-size: 25px;
    font-weight: 800;
    line-height: 58.7px;
  }

  .add-md-sect {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  /* SERVICE COMPONENT ENDS */





  /* SERVICE STYLING STARTS  */
  .main-headlines {
    font-family: Raleway;
    font-size: 35px;
    font-weight: 700;
    line-height: 41.09px;
    color: var(--content-color);
  }

  .cotent-typography {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: center;
    color: var(--content-color);
  }

  .service-mobile-row {
    /* background-color: yellow; */
    display: flex;
    overflow-y: scroll;
    display: flex;
    padding: 20px;
    gap: 20px;
    align-items: center;
    /* justify-content: center; */
  }

  /* SERVICE STYLING ENDS */


  /* CAROUSEL CUSTIOM STYLING STARTS */

  /* CAROUSEL STARTS */
  .slick-dots li {
    position: relative;
    display: inline-block;
    width: 15px !important;
    height: 15px !important;
    margin: 0 4px !important;
    padding: 0;
    cursor: pointer;
  }

  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 15px !important;
    height: 15px !important;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    background: #F2F2F2 !important;

    /* color: #fff; */
    border-radius: 50%;
    /* border: 1px solid #4fa5ea !important; */
  }

  .slick-dots li button::after {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px !important;
    height: 15px !important;
    content: '•';
    text-align: center;
    /* opacity: .25; */
    /* color: #0095FE !important; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #F2F2F2 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-dots {
    position: absolute;
    bottom: -2.3rem !important;
    /* bottom: -3rem !important; */
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }


  /* CAROUSEL ENDS */

  /*  */

  .slick-prev,
  .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 120% !important;
    bottom: 10px !important;
    display: block;
    width: 60px !important;
    height: 60px !important;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    background: black !important;
    border-radius: 50% !important;
    display: none !important;
  }



  .cards {
    height: 400px;
    /* width: 360px; */
    border: 1px solid black;
    border-radius: 30px;
    border: 1px solid #BFBABA;
    overflow: hidden;
    position: relative;
    margin: 0 10px;
  }

  .icons {
    width: 59px;
    height: 59px;
  }

  .icons img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    overflow: hidden;
  }

  .cards-title {
    font-family: Raleway;
    font-size: 25px;
    font-weight: 700;
    line-height: 29.35px;
    color: #00113C;
    /* text-align: left; */

  }

  .cards-content {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    height: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
  }



  /* CAROUSEL CUSTIOM STYLING ENDS */




  /* CAUSTUDY STARTS */
  /* REUSED CARDS */
  .content-wraper {
    position: absolute !important;
    top: -10% !important;
    right: 0 !important;
    left: 0 !important;
  }

  .content-tile {
    font-family: Raleway;
    color: #1565C0;
    text-align: center;
    font-size: 25px;
    font-weight: 700;
    line-height: 29.35px;
  }

  .content-subTitle {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    line-height: 18.78px;
    text-align: center;
    padding: 0 50px 0 50px;
    color: #000000;

  }

  .contents {
    font-family: Montserrat;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 19.5px;
  }

  .img-wraper {
    /* width: 380.96px; */
    /* height: 365.24px; */
    overflow: hidden;
    opacity: 0px;

  }

  .img-wraper-leftOne {
    /* width: 392.05px;
    height: 189.13px; */
    width: 100%;
  }

  .img-wraper img {
    width: 100%;
    height: 100%;
  }

  .img-inside-wraper {

    width: 120px;
    height: 120px;

  }

  /* CAUSTUDY ENDS */


  /* TYPOGRSHY STARTS */
  .typography-section {
    background: #F6F6F6;
    max-height: 50vh;
  }


  .typography-h1 {
    font-family: Raleway;
    font-size: 30px !important;
    font-weight: 700;
    line-height: 35.22px;
    text-align: center;

  }

  .typography-span {
    font-family: Raleway;
    font-size: 30px;
    font-weight: 700;
    line-height: 35.22px;
    text-align: center;

  }

  /* TYPOGRSHY ENDS */

  /* LOGOSCROLL SECTION STARTS */
  .logos-wraper {
    margin-right: 3rem;
    height: 70px;
    width: 150px;
  }

  .logos-wraper img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  /* LOGOSCROLL SECTION ENDS */



  /* CONTACT HEADLINES */
  .contact-h2 {
    color: #1565C0;

  }



  /* FOOTER SECTION STARTS */

  .footerLogo {
    width: 205.97px;
    height: 49.09px;
    margin-bottom: 54px;
  }

  .footerLogo img {
    width: 100%;
    height: 100%;
  }

  /* footer modal btn */
  .hideInMobile {
    display: none;
  }

  .modal-btn {
    width: 195.45px;
    height: 47.45px;
    border-radius: 6px;
    background: #1565C0;
    color: var(--primery-white);
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .footer-heades {
    font-family: Raleway;
    font-size: 25px;
    font-weight: 700;
    line-height: 29.35px;


  }

  .footer-contens {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;

  }

  .footer-img-wraper {
    width: 310.12px;
    height: 293.3px;
    top: 12528.57px;
    left: 22.8px;
    gap: 0px;
    opacity: 0px;
  }

  .footer-img-wraper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .modal-btn-wraper {
    width: 195.45px;
    height: 47.45px;
    border-radius: 6px;
    background: #1565C0;
  }

  .modal-btn-wraper .modal-btn {
    color: #fff;
  }

  /* FOOTER SECTION ENDS */


  /* MODAL STARTS */
  .modal-btn {

    background: none;
    border: none;
    color: var(--content-color);
    width: max-content;
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: center;

  }

  .contact-h1 {
    font-family: Raleway;
    font-size: 35px !important;
    font-weight: 700;
    line-height: 41.09px !important;
    text-align: left !important;
    color: var(--content-color);
  }

  .contact-h2 {
    font-family: Raleway;
    font-size: 20px !important;
    font-weight: 400;
    line-height: 23.48px !important;
    text-align: left !important;

  }

  .form-inputs {

    width: 324px !important;
    height: 54.93px !important;
    border-radius: 6px !important;
    border: 1px solid #BFBFBF;
  }

  error {

    min-width: 324px;
  }

  label.optional-label {
    min-width: 324px;
    margin: 10px 0;
  }

  textarea {
    width: 324px;
    height: 147.08px;
    border-radius: 6px;
    border: 1px solid #BFBFBF
  }

  .feedback-card {
    border: 1px solid #BFBFBF;
    border-radius: 10px;
  }

  .feedback-card h1 {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 600;
    line-height: 36.57px;
    color: var(--content-color);
  }

  .feedback-desc {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.38px;
    color: var(--content-color);
  }

  .feedback-team {
    font-family: Montserrat;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 24.38px;
    text-align: left;


  }

  .send-btn {
    width: 324px;
    height: 54.93px;
    top: 718.96px;
    left: 46.9px;
    gap: 0px;
    border-radius: 6px;
    opacity: 0px;
    background: #1565C0;
    border: none;
  }

  .button {
    width: 324px !important;
    height: 54.93px;
    border-radius: 6px;
    background: #1565C0;
    border: none;
  }

  .span-wraper {
    display: none;
  }

  /* MODAL ENDS */

  /* PROJECT ESTIMATE SECTION STARTS */
  .estimate_first_col_wraper h1 {
    font-family: Raleway;
    font-size: 35px !important;
    font-weight: 700;
    line-height: 41.09px !important;
    text-align: left !important;

  }

  .estimate_first_col_wraper span {
    font-family: Raleway;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 21.13px !important;
    color: #000000;
  }

  .filed_inputs {
    width: 363.9px !important;
    height: 54.93px !important;
    border-radius: 6px !important;
    border: 1px solid #BFBFBF !important;
    padding-left: 10px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: left;
    color: #817A7A;

  }

  label.checkboxLabel {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    text-align: left;
    color: #000000;

  }

  .golbal_label {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    text-align: left;
    color: #000000;
    /* width: max-content; */
  }

  .grid-item {
    width: max-content !important;
  }

  .p-estimate-textArea {
    width: 363.9px !important;
    height: 258.21px !important;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    background: #FFFFFF;

  }



  textarea {
    resize: vertical;
    width: 324px !important;
    height: 258.21px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
  }

  textarea#textBOx {
    width: 315.27px !important;
    height: 54px;
    color: #000000;
  }

  select {
    width: 363.9px !important;
    height: 54.93px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    color: #817A7A;
    padding-left: 15px;
  }

  .estimate_submit_btn {
    /* width: 557.46px; */
    width: 363.9px !important;
    height: 65.18px;
    border-radius: 6px;
    background: #1565C0;
    color: var(--primery-white);
    border: none;
  }

  .p-estimate-btn {
    position: absolute;
    top: 9rem;
    left: 5rem;
    background: #1565C0;
    color: var(--primery-white);
    border: none;
    outline: none;
    height: 44.47px;
    width: 203.32px;
    border-radius: 5px;
  }

  .estimate_section_wraper {
    position: relative;
  }

  /* .mobile_logo {
    width: 144.41px;
    height: 28.08px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .mobile_logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  } */


  .estimate_feedback-card {
    width: 100%;
    height: 400px;
    background-color: var(--primery-white);
    border: 1px solid #000;
  }

  /* PROJECT ESTIMATE SECTION ENDS */



  /* SM ANIMATION VFX PAGE STARTS */
  .am-vfx-card {
    width: 100% !important;
    /* width: 360px !important; */
    height: 400px !important;
    border-radius: 20px !important;
    border: 1px solid #BFBABA;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px !important;
    text-align: center;
    margin-bottom: 2rem !important;
    position: relative;
    overflow: hidden;
  }


  .am-vfx-card h5 {
    font-size: 25px !important;
    font-family: Raleway;
    font-weight: 700 !important;
    margin-bottom: 2rem !important;

  }

  .am-vfx-card span {
    font-size: 18px !important;
    font-family: Raleway;
  }

  .am-vfx-addSection {
    margin-top: 5rem;
  }

  /* SM ANIMATION VFX PAGE ENDS */


  /* ABOUT COMPONENT STARTS */
  .about-phrgh {
    font-family: Montserrat;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 21.94px !important;
    text-align: center;
    color: #000000;
  }

  .color_point {
    width: 20px !important;
    height: 20px !important;
    border-radius: 50%;
    margin-right: 10px !important;
  }

  .abt-cont h5 {
    font-family: Montserrat;
    font-size: 20px !important;
    font-weight: 700 !important;
    line-height: 21.94px !important;
    text-align: start;
    color: #000000;

  }

  .abt-cont p {
    font-family: Montserrat;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 21.94px !important;
    text-align: start;
    color: #000000;
    padding: 0 2rem !important;
  }

  .abt-card {
    background: #F8F8F8;
    width: 100% !important;
    /* height: 180.5px !important; */
    height: 200.5px !important;
    margin-bottom: 25px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;

  }

  .abt-card-span {
    font-family: Montserrat;
    font-size: 16px !important;
    font-weight: 400;
    line-height: 21.94px;
    /* text-align: start; */
    color: #000000;

  }

  .abt-card-h4 {
    font-family: Montserrat;
    font-size: 20px !important;
    font-weight: 700;
    line-height: 21.94px;
    /* text-align: start; */
    color: #000000;
  }





  /* ABOUT COMPONENT ENDS */
}



/* xs */
@media screen and (max-width: 375px) {
  .brand-logo-wraper {
    position: absolute;
    top: 2%;
    width: 144.41px;
    height: 28.08px;
  }

  .contact-wraper {
    margin-bottom: 2rem;
  }

  .padding-mobile-c {
    padding: 0px .2rem !important;
  }

  /* .carousel_item_container{
    padding: 0 4rem;
  } */

  .button2 {
    width: max-content !important;
    height: 70.8px;
    border-radius: 10px;
    display: inline-block;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: #1565C0;
    padding: 0.7em 1.7em;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    border-radius: 0.5em;
    background: #FFFFFF;
    border: 1px solid #1565C0;
  }

  .evr-landing-logo {
    top: 1rem !important;
    width: 144.41px;
    height: 28.08px;
  }

  section#contact {
    padding: 0 1rem !important;
  }

  /* .img-wraper-leftOne {
    padding: 0 1rem !important;
  } */

  .img-inside-wraper {
    width: 103px !important;
    /* width: 95px !important; */
    height: 95px !important;
  }

  .img-wraper-2 {
    /* padding: 0 1rem !important; */
    margin-top: .4rem;
  }

  .mobile-estimate-btn button {
    width: 320.81px !important;
    height: 70.8px;
    border-radius: 10px;
    background: #1565C0;
    border: 1px solid #1565C0;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    color: #FFFFFF;
    margin-top: 87px;
  }


  /* PROJECT ESTIMATION STARTS */
  .filed_inputs {
    width: 100% !important;
    height: 54.93px !important;
    border-radius: 6px !important;
    border: 1px solid #BFBFBF !important;
    padding-left: 10px;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: left;
    color: #817A7A;
  }

  .p-estimate-textArea {
    width: 100% !important;
    height: 258.21px !important;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    background: #FFFFFF;
  }

  select {
    width: 100% !important;
    height: 54.93px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    color: #817A7A;
    padding-left: 15px;
  }

  .estimate_submit_btn {
    width: 100% !important;
    height: 65.18px;
    border-radius: 6px;
    background: #1565C0;
    color: var(--primery-white);
    border: none;
  }

  /* PROJECT ESTIMATION ENDS */


  /* CAROUSEL STARTS */
  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: -3rem !important;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
  }

  /* CAROUSEL ENDS */
}







/* MD FOR TABS */
@media screen and (min-width: 768px) {

  /* here hiding this 2footer  */
  section.hideOther {
    display: none;
  }

  .estimate_submit_btn {
    /* width: 557.46px; */
    width: 100%;
    height: 65.18px;
    border-radius: 6px;
    background: #1565C0;
    color: var(--primery-white);
    border: none;
    transition: all 0.2s ease-in;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    text-align: center;
  }

  /* NAMENU STARTS */
  /* .modal-btn {
    display: none;
  } */

  /* .mobile_logo {
    display: none;
  } */



  /* NAMENU ENDS */


  /* LANDING PAGE STARTS */


  main.position-relative.layout {
    min-height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;

  }


  .home-carousel-h1 {
    font-family: Raleway;
    font-size: 35px;
    font-weight: 700;
    line-height: 58.7px;
    color: var(--content-color);
  }

  .home-carousel-h1-span {
    font-family: Raleway;
    font-size: 35px;
    font-weight: 700;
    line-height: 58.7px;
    text-align: left;
    color: #1565c0;
  }


  .home-carousel-p {
    font-family: Raleway;
    font-size: 25px;
    font-weight: 600;
    line-height: 58.7px;
  }

  .home-carousel-span {
    font-family: Raleway;
    font-size: 25px;
    font-weight: 400;
    line-height: 42.7px;
  }

  .button2 {
    width: 424.95px;
    height: 70.8px;
    border-radius: 10px;
    display: inline-block;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    z-index: 1;
    color: #1565C0;
    padding: 0.7em 1.7em;
    cursor: pointer;
    font-family: Raleway !important;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    border-radius: 0.5em;
    background: #FFFFFF;
    border: 1px solid #1565C0;
  }




  /* LANDING PAGE ENDS */


  /* BANNER SECTION STARTS */
  .custom-row::before {
    content: "";
    position: absolute;
    background: #1565C0;
    top: 1rem;
    left: 0.5rem;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    z-index: -1;
  }

  .custom-row {
    background: linear-gradient(87.62deg, #2998FE 0.72%, #4FA1FF 97.21%);
    width: 100%;
    border-radius: 20px;
  }

  .mobile-estimate-btn button {
    width: 460px;
    height: 70.8px;
    border-radius: 10px;
    background: #1565C0;
    border: 1px solid #1565C0;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    color: #FFFFFF;
    margin-top: 87px;

  }

  .banner-line:nth-child(2) {
    /* height: 128.41px; */
    border-bottom: 1px solid #FFFFFF80;
    border-top: 1px solid #FFFFFF80;
  }

  .banner-line h3 {
    font-family: Raleway;
    font-size: 25px !important;
    font-weight: 700 !important;
    line-height: 29.35px;
    text-align: left;
    color: #FFFFFF;
    margin-bottom: 17px;
  }

  .banner-line span {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: left;
    color: #FFFFFF;

  }

  /* BANNER SECTION ENDS */



  /* SERVICE SECTION STARTS */
  .contact-btn-modal {
    display: none;
  }

  .main-headlines {
    font-family: Raleway;
    font-size: 40px;
    font-weight: 700;
    line-height: 41.09px;
    color: var(--content-color);
  }


  /* SERVICE SECTION ENDS */



  /* CAUSE STUDY STARTS  */
  .img-wraper {
    width: 680px;
    height: 510px;
  }

  .img-wraper img {
    width: 100%;
    height: 100%;
  }

  /* CAUSE STUDY ENDS */

  /*TYPOGRAPHY STARTS  */
  typography-h1 {
    font-family: Raleway;
    font-size: 35px;
    font-weight: 700;
    line-height: 35.22px;
    text-align: center;
  }

  /*TYPOGRAPHY ENDS */
}


/* FOR REMOVING SERVICE SECTION CARD AND SVG LINE */
@media screen and (max-width: 992px) {
  .hideIn-mobile {
    display: none;
  }

  .mobile_logo {
    width: 144.41px;
    height: 28.08px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .mobile_logo img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .layout {
    overflow: hidden;
  }

  .link_2 {
    display: none;
  }

  .cards {
    height: 400px;
    /* width: 360px; */
    border: 1px solid black;
    border-radius: 30px;
    border: 1px solid #BFBABA;
    overflow: hidden;
    position: relative;
    margin: 0 10px;
  }

  .designPage-svg-wraper {
    display: none;
  }

  .content-wraper {
    position: absolute;
    top: -12%;
    right: 0;
    left: 0;
  }

  .contents {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    /* line-height: 19.5px; */
    line-height: 23.5px;
  }

  .mobile-nav {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--primery-white);
    border-radius: 20px 20px 0 0;
    z-index: 99;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 2);
    height: 70px;
  }


  /* logoscroll starts */
  .logos-wraper {
    margin-right: 3rem;
    height: 70px;
    width: 150px;
  }

  .logos-wraper img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  /* logoscroll ends */

  /* contact sections starts */
  .contact-h1 {
    font-family: Raleway;
    font-size: 35px;
    font-weight: 700;
    line-height: 41.09px;
    text-align: left;
    color: var(--content-color);
  }

  .contact-h2 {
    font-family: Raleway;
    font-size: 25px;
    font-weight: 400;
    line-height: 23.48px;
    text-align: left;
    color: #1565C0;
  }

  .form-inputs {
    width: 80%;
    height: 54.93px;
    border-radius: 6px;
    border: 1px 0px 0px 0px;
    border: 1px solid #BFBFBF;
  }

  .md-text {
    width: 80%;
    height: 258.21px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
  }

  /* contact sections ends */

  /* footer starts  */
  .bottom-footer-contens {
    width: max-content;
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    text-align: left;

  }

  /* footer ends */


  /* PROJECT ESTIMATE STARTS */
  .estimate_first_col_wraper h1 {
    font-family: Raleway;
    font-size: 35px;
    font-weight: 700;
    line-height: 41.09px;
    text-align: left;
  }

  .estimate_first_col_wraper span {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    color: #000000;
  }

  .filed_inputs {
    width: 80%;
    height: 54.93px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    padding-left: 10px;
  }

  .hide-estimate_mobile {
    display: none;
  }

  /* textarea#textBOx {
    width: 450px;
    height: 54px;
    color: #000000;
  } */

  .p-estimate-textArea {
    width: 80%;
    height: 258.21px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    background: #FFFFFF;
  }

  select {
    width: 80%;
    height: 54.93px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    color: #817A7A;
    padding-left: 15px;
  }

  .estimate_submit_btn {
    /* width: 557.46px; */
    width: 80%;
    height: 65.18px;
    border-radius: 6px;
    background: #1565C0;
    color: var(--primery-white);
    border: none;
  }

  /* PROJECT ESTIMATE ENDS */

  /* NOTFOUND PAGE STARTS */


  section.notFOund-section {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .notFound_img_wraper {
    width: 100%;
    height: 100%;
    border-radius: 36px;
    overflow: hidden;
  }

  .notFound_img_wraper img {
    width: 100%;
    height: 100%;
  }

  .notfound_title {
    font-family: Raleway;
    font-size: 35px;
    font-weight: 800;
    line-height: 85.7px;
    text-align: left;
    color: #000000;
  }

  .notfound-desc {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 29.26px;
    text-align: left;
    color: #000000;
  }

  /* NOTFOUND PAGE ENDS */


  /* ABOUT SECTION STARTS */
  .abt-h1,
  .amvfx-h1 {
    font-family: Raleway;
    font-size: 35px;
    font-weight: 700;

    color: #000000;
  }

  .about-phrgh,
  .abut-p {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 24.94px;
    text-align: center;
    color: #000000;
  }

  .color_point {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }

  .abt-cont p {
    font-family: Raleway;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: start;
    color: #000000;
    padding: 0 2.5rem;
  }

  .abt-cont h5 {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.94px;
    text-align: start;
    color: #000000;
  }

  .abt-card {
    background: #F8F8F8;
    width: 600px;
    height: 180.5px;
    margin-bottom: 25px;
  }

  .abt-card-h4 {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 700;
    line-height: 21.94px;
    /* text-align: start; */
    color: #000000;
  }

  .abt-card-span {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    /* text-align: start; */
    color: #000000;
  }

  .abt-card-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .about_svg_wraper {
    display: none;
  }

  /* ABOUT SECTION ENDS */


  /* Animation and vfx stars */
  .amvfx-section {
    margin-top: 7rem;
    position: relative !important;
  }

  .designPage-section {
    position: relative !important;
  }

  .abut-p,
  .amvfx-span {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-align: center;
    color: #000000;
    padding: 0 2rem;
  }

  .amvfx-img-wrapper {
    width: 100%;
  }

  .amvfx-img-wrapper img {
    width: 100%;
  }

  .am-vfx-row {
    display: none;
  }

  .am-vfx-card {
    width: 330px;
    height: 400px;
    border-radius: 20px;
    border: 1px solid #BFBABA;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 15px;
    text-align: center;
    margin-bottom: 2rem;
    position: relative;
    overflow: hidden;
  }

  .am-vfx-card h5 {
    font-size: 25px;
    font-family: Raleway;
    font-weight: 700;
    margin-bottom: 2rem;
  }



  .am-vfx-card span {
    font-size: 18px !important;
  }

  .am-vfx-addS-svg {
    display: none;
  }


  /* Animation and vfx ends */






}



@media (min-width: 428px) and (max-width: 766px) {
  .add_spacing {
    padding: 0 3rem !important;
  }
}

@media (min-width: 376px) and (max-width: 400px) {
  .img-inside-wraper {
    width: 110px;
    height: 110px;
  }

  .img-wraper-2 {
    margin-top: 0.7rem;
  }

  .padding-mobile-c {
    padding: 0 !important;
  }

}

@media (min-width: 450px) and (max-width: 579px) {
  .img-inside-wraper {
    width: 100%;
    height: 100%;
  }

  .img-wraper-2 {
    margin-top: 0.7rem;
  }

  .padding-mobile-c {
    padding: 0 !important;
  }

}


@media (min-width: 580px) and (max-width: 762px) {

  .img-inside-wraper {
    width: 160px;
    height: 120px;
  }

  .img-wraper-2 {
    padding-top: 1rem !important;
  }
}


@media (min-width: 390px) and (max-width: 390px) {
  section#contact {
    padding: 0 1.3rem;
  }

  .img-inside-wraper {
    width: 110px;
    height: 110px;
  }
}


@media (min-width: 768px) and (max-width: 992px) {


  .modal-btn-wraper .modal-btn {
    display: none;
  }

  .feedback-card {
    width: 70%;
    border-radius: 28px;
    border: 1px solid #BFBFBF;
  }

  .feedback-card h1 {
    font-family: Montserrat !important;
    font-size: 30px !important;
    font-weight: 600 !important;
    line-height: 36.57px !important;
    text-align: left;
    color: #000000 !important;

  }

  .feedback-team {
    font-family: Montserrat;
    font-size: 20px;
    font-style: italic;
    font-weight: 400;
    line-height: 24.38px;


  }

  .feedback-desc {
    font-family: Montserrat;
    font-size: 20px;
    font-weight: 400;
    line-height: 24.38px;


  }

  /* LANDING PAGAE STARTS */
  .evr-landing-logo {
    top: 2rem;
    width: 144.41px;
    height: 28.08px;
  }

  .evr-landing-logo img {
    width: 100%;
    height: 100%;
  }

  .carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0rem !important;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 0rem !important;
    margin-left: 15%;
  }

  .carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    height: 650px !important;
    margin-right: -100%;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out;
  }

  .home-carousel-h1-span {
    font-family: Raleway;
    font-size: 50px;
    font-weight: 700;
    line-height: 58.7px;
    text-align: left;
    color: #1565c0;
  }

  .home-carousel-h1 {
    font-family: Raleway;
    font-size: 50px;
    font-weight: 700;
    line-height: 58.7px;
    color: var(--content-color);
  }

  .home-carousel-span {
    font-family: Raleway;
    font-size: 25px;
    font-weight: 400;
    line-height: 36.7px;
  }

  .cards {
    height: 400px;

    border: 1px solid black;
    border-radius: 30px;
    border: 1px solid #BFBABA;
    overflow: hidden;
    position: relative;
    margin: 0 10px;
    padding: 2rem !important;
  }


  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 15px !important;
    border-radius: 50% !important;
    height: 15px !important;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #1565C0 !important;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
  }

  .carousel-inner {

    /* min-height: 80vh; */
    display: flex;
    align-items: center;
  }

  /* LANDING PAGAE ENDS */



  /* CAROUSEL STARTS */
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 20px;
    height: 20px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
    background: #F2F2F2 !important;

    /* color: #fff; */
    border-radius: 50%;
    /* border: 1px solid #4fa5ea !important; */
  }

  .slick-dots li button::after {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    /* opacity: .25; */
    /* color: #0095FE !important; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    content: '•';
    text-align: center;
    opacity: .25;
    color: #F2F2F2 !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .slick-dots {
    position: absolute;
    bottom: -2rem !important;
    /* bottom: -2.5rem !important; */
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  /* CAROUSEL ENDS */


  /* CARDS STARS */
  .cards-content.pe-md-2 {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.94px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .cards-title {
    font-family: Raleway;
    font-size: 25px;
    font-weight: 700;
    line-height: 29.35px;
    color: #00113C;

  }

  /* CARDS ENDS */

  .am-vfx-addSection {
    margin-top: 5rem;
  }

  .add-md-sect {
    display: flex;
    justify-content: space-between;
  }

  .am-vfx-h3 {
    font-family: Raleway;
    font-size: 35px;
    font-weight: 800;
    line-height: 60.95px;
    color: #1565C0;
    text-decoration: underline;
  }

  .am-vfx-h3 span {
    font-family: Raleway;
    font-size: 35px;
    font-weight: 800;
    line-height: 58.7px;
  }


  /* PROJECT ESTIMATE STARTS */
  .golbal_label {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 400;
    line-height: 21.13px;
    text-align: left;
    color: #000000;
  }

  label.checkboxLabel {
    font-family: Raleway;
    font-size: 20px;
    font-weight: 400;
    line-height: 21.13px;
    color: #000000;
  }

  input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border: 2px solid #ccc;
    transition: all 0.2s ease-in-out;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    margin-right: 5px;
  }

  textarea#textBOx {
    width: 450px;
    height: 54px;
    border-radius: 6px;
    border: 1px solid #BFBFBF;
    background: #FFFFFF;
  }

  /* PROJECT ESTIMATE ENDS */



  /* NAVBAR STARTS */
  .link a {
    text-decoration: none;
    color: var(--content-color);
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: center;
  }

  .nav-brand-logo {
    display: none;
  }

  .mobile-nav {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }

  .p-estimate-btn {
    background: #1565C0;
    color: var(--primery-white);
    border: none;
    outline: none;
    height: 48.9px;
    width: 168.35px;
    border-radius: 5px;
    transition: all 0.2s ease-in;
  }

  /* NAVBAR ENDS */


  /* FOOTER STARTS */
  span.bottom-footer-span {
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.5px;
    text-align: left;
    color: #000000;

  }

  .footerLogo {
    width: 205.97px;
    height: 49.09px;
    margin-bottom: 2rem;
  }

  .footerLogo img {
    width: 100%;
    height: 100%;
  }

  .modal-btn {
    width: 195.45px;
    height: 47.45px;
    border-radius: 6px;
    background: #1565C0;
    border: none;
    color: var(--primery-white);
    font-family: Raleway;
    font-size: 16px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* FOOTER ENDS */


  /* CAUSESTUDY STARTS */
  .img-inside-wraper {
    width: 220px;
    height: 220px;
  }

  .img-wraper-2 {
    margin-top: 0.5rem;
  }

  /* CAUSESTUDY ENDS */


  /* FOOTER STARTS */
  .footer-sub-heads {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.13px;
    text-align: left;
    color: #00113C;
    width: max-content;
  }

  /* FOOTER ENDS */


  /* CONTACT STARTS */
  .span-label {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    margin-left: 1.5rem;
    font-family: Raleway;
  }

  .am-vfx-card span {
    font-size: 18px !important;
    font-family: Raleway;
  }

  /* CONTACT ENDS */
}

/* for 1024 devices only */
@media only screen and (min-width: 1024px) and (max-width: 1024px) {


  /* LANDING PAGE */
  .carousel-indicators {
    position: absolute;
    right: 0;
    /* bottom: 5rem !important; */

    bottom: -3rem !important;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
  }


  .carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 15px !important;
    border-radius: 50% !important;
    height: 15px !important;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #1565C0 !important;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
  }

  .carousel-inner {

    min-height: 80vh;
    display: flex;
    align-items: center;
  }




  .banner-section {
    height: 100vh;
    /* height: 20vh; */
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* BANNER SECTION ENDS */

  /* SERVICES STARTS */
  .cards-title {
    font-family: Raleway;
    font-size: 30px;
    font-weight: 700;
    line-height: 35.22px;
    color: #00113C;
    width: 100%;
  }

  /* SERVICES ENDS */

  /* CAUSTDU STARTS */
  .img-wraper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .img-inside-wraper {
    width: 143px;
    height: 160px;
  }

  /* CAUSTDU ENDS */


  /* ABOUT SECTION STARTS */
  .abt-card {
    background: #F8F8F8;
    width: 100%;
    height: 223px;
    margin-bottom: 84px;
  }

  /* ABOUT SECTION ENDS */


  /* PROJECT ESTIMATE STATS */
  .img-3 {
    width: 250px;
    top: 20rem;
    left: 0rem;
  }

  .img-2 {
    width: 600px;
    top: 10rem;
    left: 0rem;
  }

  .img-1 {
    height: 500px;
    left: 21rem;
    top: 1rem;
  }

  .inside_round_box {
    border-radius: 50%;
    background: #BFA9FF;
    width: 400px;
    height: 400px;
    left: 17rem;
    top: 7rem;
  }

  .feedback-card {
    width: 100%;
    height: 100%;
    border-radius: 28px;
    border: 1px solid #BFBFBF;
  }

  /* PROJECT ESTIMATE ENDS */


  /* ANIMATION VFX STARTS */
  .amvfx-h1 {
    font-family: Montserrat;
    font-size: 80px;
    font-weight: 700;
    line-height: 97.52px;
    width: 100%;
  }

  /* ANIMATION VFX ENDS */


  /* FOOTER STARTS */
  .bottom-footer-contens {
    width: max-content;
    font-family: Raleway;
    font-size: 15px;
    font-weight: 400;
    line-height: 21.13px;
    color: #00113C;
  }

  .footer-sub-heads {
    font-family: Raleway;
    font-size: 18px;
    font-weight: 700;
    line-height: 21.13px;
    text-align: left;
    color: #00113C;
  }

  .about-row {
    padding: 5rem 0 !important;
  }

  .pro-container {
    margin-top: 4rem;
  }

  /* FOOTER ENDS */
}












.button {
  position: relative;
  padding: 10px 22px;
  border-radius: 6px;
  border: none;
  color: #fff;
  cursor: pointer;
  background-color: #1565c0;
  transition: all 0.2s ease;
  width: 80%;
  min-height: 54px;
  border: none;
  outline: none;
  border-radius: 6px;
  font-family: Raleway;
  font-size: 20px;
  font-weight: 700;
  line-height: 23.48px;
}

.button:active {
  transform: scale(0.96);
}

.button:hover {
  background-color: #1E40AF;
}


/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* select custom arrow */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('./Assets/Images/Polygon\ 1.png') no-repeat right center;
  background-position-x: 94%;
  background-size: 1em;
  padding-right: 1.5em;
  /* Adjust based on the size of your arrow image */
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  font-family: Montserrat;
  font-size: 18px !important;
  font-weight: 400;
  line-height: 21.94px;
  text-align: left;
  color: #817A7A;



}



select option {
  background: #ffffff15;
  backdrop-filter: blur(5px);

  /* Change this to your desired background color */
}


/* Remove arrows in Webkit browsers (Chrome, Safari) */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Remove arrows in Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}


/* PRELOADER ANIAMTIONS  */

.item {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preloder-container {
  overflow: hidden;
}

.preloder-h1 {
  font-family: Raleway;
  font-size: 30px;
  font-weight: 900;
  /* line-height: 93.92px; */
  color: var(--content-color);
}

.loader {
  width: 45px;
  height: 40px;
  background: linear-gradient(#0000 calc(1*100%/6), #000 0 calc(3*100%/6), #0000 0),
    linear-gradient(#0000 calc(2*100%/6), #000 0 calc(4*100%/6), #0000 0),
    linear-gradient(#0000 calc(3*100%/6), #000 0 calc(5*100%/6), #0000 0);
  background-size: 10px 400%;
  background-repeat: no-repeat;
  animation: matrix 1s infinite linear;
}

@keyframes matrix {
  0% {
    background-position: 0% 100%, 50% 100%, 100% 100%
  }

  100% {
    background-position: 0% 0%, 50% 0%, 100% 0%
  }
}